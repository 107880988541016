<template>
  <div class="trading_tool_mt4">
    <section class="sec1">
      <p class="title">{{ $t('tradingTool.mt4.mt4Text1') }}</p>
      <p class="word">{{ $t('tradingTool.mt4.mt4Text2') }}</p>
      <p class="word">{{ $t('tradingTool.mt4.mt4Text3') }}</p>
      <div class="line"></div>
    </section>
    <section class="sec2">
      <div class="download" @click=onDownload(1)>
        <div class="icon"></div>
        <p>Mac<span>{{ $t('common.button.download') }}</span></p>
      </div>
      <div class="download" @click=onDownload(2)>
        <div class="icon"></div>
        <p>Windows<span>{{ $t('common.button.download') }}</span></p>
      </div>
      <div class="download" @click=onDownload(3)>
        <div class="icon"></div>
        <p>iOS<span>{{ $t('common.button.download') }}</span></p>
      </div>
      <div class="download" @click=onDownload(4)>
        <div class="icon"></div>
        <p>Android<span>{{ $t('common.button.download') }}</span></p>
      </div>

    </section>
    <section class="sec3">
      <div class="cont_left">
        <div class="title">
          <img src="@/assets/images-1/tradingTool/mt4_icon.png" alt="">
          <span>
            MeTa Trader 4
          </span>
        </div>
        <p class="desc">{{ $t('tradingTool.mt4.mt4Text4') }}</p>
      </div>
      <img src="@/assets/images-1/tradingTool/mt4_sec3_1.png" alt="" class="center_img" />
      <div class="cont_right">
        <p v-html="$t('tradingTool.mt4.mt4Text5')"></p>
        <p v-html="$t('tradingTool.mt4.mt4Text6')"></p>
        <p v-html="$t('tradingTool.mt4.mt4Text7')"></p>
        <p>{{ $t('tradingTool.mt4.mt4Text8') }}</p>
        <p>{{ $t('tradingTool.mt4.mt4Text9') }}</p>
      </div>
    </section>
    <section class="sec4">
      <div class="cont_left">
        <p class="title">{{ $t('tradingTool.mt4.mt4Text10') }}</p>
        <p class="desc">{{ $t('tradingTool.mt4.mt4Text11') }}</p>
      </div>
      <img src="@/assets/images-1/tradingTool/mt4_sec4_1.png" alt="" />
      <div class="cont_right">
        <p>{{ $t('tradingTool.mt4.mt4Text12') }}</p>
        <p>{{ $t('tradingTool.mt4.mt4Text13') }}</p>
        <p>{{ $t('tradingTool.mt4.mt4Text14') }}</p>
        <p>{{ $t('tradingTool.mt4.mt4Text15') }}</p>
        <p>{{ $t('tradingTool.mt4.mt4Text16') }}</p>
      </div>
    </section>
    <section class="sec5">
      <div class="cont_left">
        <p class="title">{{ $t('tradingTool.mt4.mt4Text17') }}</p>
        <p class="word">{{ $t('tradingTool.mt4.mt4Text18') }}</p>
        <img src="@/assets/images-1/tradingTool/mt4_sec5_1.png" alt="">
        <div class="download">
          <div @click=onDownload(1)>
            <div class="icon">
              <svg-icon iconClass="mac"></svg-icon>
            </div>
            <span>Mac{{ $t('common.button.download') }}</span>
          </div>
          <div @click=onDownload(3)>
            <div class="icon">
              <svg-icon iconClass="ios"></svg-icon>
            </div>
            <span>iOS{{ $t('common.button.download') }}</span>
          </div>
          <div @click=onDownload(2)>
            <div class="icon">
              <svg-icon iconClass="windows"></svg-icon>
            </div>
            <span>Windows{{ $t('common.button.download') }}</span>
          </div>
          <div @click=onDownload(4)>
            <div class="icon">
              <svg-icon iconClass="android"></svg-icon>
            </div>
            <span>Android{{ $t('common.button.download') }}</span>
          </div>
          <div @click=onDownload(5)>
            <div class="icon">
              <svg-icon iconClass="mt4"></svg-icon>
            </div>
            <span>Web Trader</span>
          </div>
        </div>
      </div>
      <img src="@/assets/images-1/tradingTool/mt4_sec5_1.png" alt="">
    </section>
    <section class="sec6">
      <p class="title">{{ $t('tradingTool.mt4.mt4Text23') }}</p>
      <div class="cont">
        <div>
          <img src="@/assets/images-1/tradingTool/mt4_sec6_4.png" class="num num1" alt="">
          <img src="@/assets/images-1/tradingTool/mt4_sec6_1.png" alt="">
          <p class="secondary_title">
            {{ $t('tradingTool.mt4.mt4Text19') }}
          </p>
          <p class="word">
            {{ $t('tradingTool.mt4.mt4Text20') }}
          </p>
        </div>
        <div>
          <img class="num num2" src="@/assets/images-1/tradingTool/mt4_sec6_5.png" alt="">
          <img src="@/assets/images-1/tradingTool/mt4_sec6_2.png" alt="">
          <p class="secondary_title">
            {{ $t('common.button.download') }}MT4
          </p>
          <p class="word">
            {{ $t('tradingTool.mt4.mt4Text21') }}
          </p>
        </div>
        <div>
          <img class="num num3" src="@/assets/images-1/tradingTool/mt4_sec6_6.png" alt="">
          <img src="@/assets/images-1/tradingTool/mt4_sec6_3.png" alt="">
          <p class="secondary_title">
            {{ $t('deposit.default.deposit') }}
          </p>
          <p class="word">
            {{ $t('tradingTool.mt4.mt4Text22') }}
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'mt4',
  data() {
    return {
    };
  },
  computed: {
    getMt4Links() {
      return this.$config.getMetaTraderLinks(this.regulator)?.mt4
    }
  },
  methods: {
    onDownload(type) {
      if (type === 1) {
        window.open(this.getMt4Links.METATRADER4_MAC, '_blank')
      } else if (type === 2) {
        window.open(this.getMt4Links.METATRADER4_windows, '_blank')
      } else if (type === 3) {
        window.open(this.getMt4Links.METATRADER4_iOS, '_blank')
      } else if (type === 4) {
        window.open(this.getMt4Links.METATRADER4_Android, '_blank')
      } else {
        this.$emit('goWebTrader')
      }
    }
  }
};
</script>
<style lang="scss">
.trading_tool_mt4 {

  .sec1 {
    height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding: 0 25%;
    background: url('~@/assets/images-1/tradingTool/mt4_sec1_bg.png') no-repeat;
    background-size: cover;
    background-position: center;
    color: $white;
    border-radius: 8px 8px 0 0;

    @include screen-mobile {
      height: auto;
      padding: 32px 40px;
    }

    @media (min-width: 781px) and (max-width: 1200px) {
      padding: 0 12%;
    }

    .title {
      font-size: 32px;
      line-height: 48px;
      font-weight: 700;
      margin-bottom: 16px;

      @include screen-mobile {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .word {
      font-size: 16px;
      line-height: 24px;

      @include screen-mobile {
        font-size: 12px;
        line-height: 18px;
      }
    }

    .line {
      width: 74px;
      height: 1px;
      background: $white;
      margin-top: 24px;

      @include screen-mobile {
        width: 24px;
        margin-top: 16px;
      }
    }
  }

  .sec2 {
    display: flex;

    .download {
      position: relative;
      background: $secondary;
      width: 25%;
      padding: 44px 24px 32px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include screen-mobile {
        padding: 16px 0;
      }

      .icon {
        width: 40px;
        height: 40px;
      }

      &:nth-child(1) {
        .icon {
          background: url('~@/assets/images-1/tradingTool/mac.png') no-repeat;
          background-size: 100%;
        }
      }

      &:nth-child(2) {
        .icon {
          background: url('~@/assets/images-1/tradingTool/windows.png') no-repeat;
          background-size: 100%;
        }
      }

      &:nth-child(3) {
        .icon {
          background: url('~@/assets/images-1/tradingTool/ios.png') no-repeat;
          background-size: 100%;
        }
      }

      &:nth-child(4) {
        .icon {
          background: url('~@/assets/images-1/tradingTool/android.png') no-repeat;
          background-size: 100%;
        }
      }

      p {
        font-size: 16px;
        color: $white;
        line-height: 24px;
        margin-top: 26px;
        word-wrap: break-word;
        width: 100%;
        text-align: center;

        @include screen-mobile {
          margin-top: 16px;

          span {
            display: none;
          }
        }
      }

      &+.download {
        &::before {
          content: '';
          position: absolute;
          display: block;
          left: 0;
          width: 1px;
          height: 40px;
          background: $white;
        }
      }

      &:hover {
        background: $white;
        cursor: pointer;

        .icon {
          background: url('~@/assets/images-1/tradingTool/download.png') no-repeat;
          background-size: 100%;
        }

        p {
          color: $text-primary;
        }
      }


      &:nth-child(-n + 3) {
        &:hover {
          &::after {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            right: -1px;
            width: 1px;
            height: 100%;
            background: $white;
            z-index: 1;
          }
        }
      }
    }

  }

  .sec3 {
    background: $white;
    padding: 0 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @include screen-mobile {
      flex-direction: column;
      padding: 32px 24px;
    }

    @media (min-width: 781px) and (max-width: 1200px) {
      padding: 0 24px;
    }



    .cont_left {
      width: 366px;

      @include screen-mobile {
        width: 100%;
      }

      .title {
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        @include screen-mobile {
          margin-bottom: 16px;
        }

        @media (min-width: 781px) and (max-width: 1200px) {
          margin-bottom: 24px;
        }

        img {
          width: 46px;
          height: 43px;
          @include rtl-sass-prop(margin-right, margin-left, 16px);

          @include screen-mobile {
            width: 32px;
            height: 32px;
          }

        }

        span {
          font-size: 32px;
          color: $text-title;
          line-height: 48px;

          @media (max-width: 1200px) {
            font-size: 24px;
            line-height: 36px;
          }
        }

      }

      .desc {
        font-size: 16px;
        color: $text-primary;
        line-height: 24px;
        @include rtl-sass-prop-dual(text-align, left, text-align, right);

      }
    }

    .center_img {
      width: 290px;
      height: 298px;
      margin: 60px 24px 0;

      @include screen-mobile {
        width: 156px;
        height: 160px;
        margin: 16px 0;
      }

      @media (min-width: 781px) and (max-width: 1200px) {
        width: 220px;
        height: 220px;
      }
    }

    .cont_right {
      width: 366px;

      @include screen-mobile {
        width: 100%;
      }

      p {
        font-size: 14px;
        color: $text-primary;
        line-height: 22px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 22px;
        @include rtl-sass-prop(padding-left, padding-right, 22px);

        span {
          font-size: 24px;
          color: $text-primary;
          line-height: 22px;
          font-weight: 700;
          margin: 0 2px;
        }

        &::before {
          content: '';
          position: absolute;
          display: block;
          top: 3px;
          @include rtl-sass-prop(left, right, 0);
          bottom: 0;
          width: 14px;
          height: 14px;
          background: url('~@/assets/images-1/tradingTool/icon_blue_check.png') no-repeat;
          background-size: 100% 100%;
          background-position: center center;
        }
      }

      p:nth-last-child(1) {
        margin-bottom: 0px;
      }
    }
  }

  .sec4 {
    background: $secondary;
    padding: 60px 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @include screen-mobile {
      flex-direction: column;
      padding: 32px 24px;
    }

    @media (min-width: 781px) and (max-width: 1200px) {
      padding: 60px 24px;
    }

    .cont_left {
      width: 366px;

      @include screen-mobile {
        width: 100%;
      }

      .title {
        font-size: 32px;
        color: $white;
        line-height: 48px;
        margin-bottom: 40px;

        @include screen-mobile {
          font-size: 24px;
          line-height: 36px;
          margin-bottom: 16px;
          @include rtl-sass-prop-dual(text-align, left, text-align, right);
        }

        @media (min-width: 781px) and (max-width: 1200px) {
          font-size: 24px;
          line-height: 36px;
          margin-bottom: 24px;
        }
      }

      .desc {
        font-size: 14px;
        color: $white;
        line-height: 22px;

        @include screen-mobile {
          @include rtl-sass-prop-dual(text-align, left, text-align, right);
        }
      }
    }

    img {
      width: 190px;
      height: 238px;
      margin: 0 74px;

      @include screen-mobile {
        width: 128px;
        height: 160px;
        margin: 16px 0;
      }

      @media (min-width: 781px) and (max-width: 1200px) {
        width: 128px;
        height: 160px;
        margin: 0 32px;
      }
    }

    .cont_right {
      width: 366px;

      @include screen-mobile {
        width: 100%;
      }

      p {
        font-size: 14px;
        color: $white;
        line-height: 22px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 22px;
        @include rtl-sass-prop(padding-left, padding-right, 22px);

        &::before {
          content: '';
          position: absolute;
          display: block;
          top: 3px;
          @include rtl-sass-prop(left, right, 0);
          bottom: 0;
          width: 14px;
          height: 14px;
          background: url('~@/assets/images-1/tradingTool/icon_blue_check.png') no-repeat;
          background-size: 100% 100%;
          background-position: center center;
        }
      }

      p:nth-last-child(1) {
        margin-bottom: 0px;
      }
    }
  }

  .sec5 {
    background: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 30px 60px 48px;

    @include screen-mobile {
      flex-direction: column;
      padding: 32px 24px;
    }

    .cont_left {
      .title {
        font-size: 32px;
        color: $text-title;
        line-height: 48px;
        font-weight: 600;
        margin-bottom: 40px;

        @include screen-mobile {
          font-size: 24px;
          line-height: 36px;
          margin-bottom: 16px;
        }
      }

      .word {
        font-size: 16px;
        color: $text-primary;
        line-height: 24px;
        margin-bottom: 16px;

        @include screen-mobile {
          font-size: 16px;
          line-height: 24px;
        }
      }

      img {
        display: none;
        width: 286px;
        height: 160px;
        @include rtl-sass-prop(margin-left, margin-right, 50%);
        @include rtl-sass-prop-dual(transform, translateX(-50%), transform, translateX(50%));

        @include screen-mobile {
          display: block;
        }
      }

      .download {
        display: flex;
        flex-wrap: wrap;

        >div {
          width: 50%;
          display: flex;
          align-items: center;
          margin-top: 24px;
          cursor: pointer;

          @include screen-mobile {
            width: 100%;

            &:nth-child(1) {
              margin-top: 16px;
            }
          }

          .icon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: $secondary;
            flex: none;
            text-align: center;
            padding-top: 8px;
            @include rtl-sass-prop(margin-right, margin-left, 16px);

            svg {
              font-size: 24px;
            }
          }

          span {
            font-size: 14px;
            color: $text-primary;
            line-height: 22px;
            display: inline-block;
            width: 60%;
            word-wrap: break-word;
          }

          &:hover {
            .icon {
              background: $primary;
            }

            span {
              color: $primary;
            }
          }
        }
      }
    }

    >img {
      @include screen-mobile {
        display: none;
      }

      @media (min-width: 781px) and (max-width: 992px) {
        width: 286px;
        height: 160px;
      }

      @media (min-width: 993px) and (max-width: 1440px) {
        width: 388px;
        height: 216px;
      }

      @media (min-width: 1201px) and (max-width: 1440px) {
        width: 500px;
        height: 278px;
      }
    }
  }

  .sec6 {
    background: $secondary;
    padding: 60px 48px;
    border-radius: 0 0 8px 8px;

    @include screen-mobile {
      padding: 32px 24px;
    }

    .title {
      font-size: 32px;
      color: $white;
      line-height: 48px;
      font-weight: 600;
      margin-bottom: 24px;
      text-align: center;

      @include screen-mobile {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 16px;
      }
    }

    .cont {
      display: flex;
      justify-content: space-between;

      @media (max-width: 992px) {
        flex-direction: column;
      }

      >div {
        width: 33.33%;
        background: rgba(255, 255, 255, .2);
        border-radius: 16px;
        padding: 40px 16px;
        position: relative;
        text-align: center;
        color: $white;
        @include rtl-sass-prop(margin-right, margin-left, 16px);


        @media (max-width: 992px) {
          width: 100%;
          margin: 0 0 16px 0;

          &:nth-last-child(1) {
            margin-bottom: 0;
          }
        }

        &:nth-last-child(1) {
          margin-right: 0;
        }

        .num {
          position: absolute;
          top: 8px;
          left: 8px;
        }

        .num1 {
          width: 86px;
          height: 100px;

          @include screen-mobile {
            width: 60px;
            height: 70px;
          }

          @media (min-width: 992px) and (max-width: 1200px) {
            width: 60px;
            height: 70px;
          }

        }

        .num2 {
          width: 101px;
          height: 100px;

          @include screen-mobile {
            width: 70px;
            height: 70px;
          }

          @media (min-width: 992px) and (max-width: 1200px) {
            width: 70px;
            height: 70px;
          }
        }

        .num3 {
          width: 102px;
          height: 100px;

          @include screen-mobile {
            width: 71px;
            height: 70px;
          }

          @media (min-width: 992px) and (max-width: 1200px) {
            width: 71px;
            height: 70px;
          }
        }

        .secondary_title {
          font-size: 18px;
          line-height: 28px;
          font-weight: 700;
          margin: 24px 0 8px;
        }

        .word {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }


  }


}
</style>

  