<template>
  <div class="trading_tool_copytrade">
    <section class="sec1">
      <p class="title">{{ $t('tradingTool.copytrade.copytrade1') }}</p>
      <p class="word">{{ $t('tradingTool.copytrade.copytrade2') }}</p>
      <div class="line"></div>
      <button @click="goUrl">{{ $t('tradingTool.copytrade.tradeNow') }}</button>
    </section>
    <section class="sec2">
      <p class="title">{{ $t('tradingTool.copytrade.rank') }}</p>
      <div class="trade-container" v-loading="loading">
        <iframe id="widgetFrame" :style="{ minWidth: '100%', height: iframeHeight + 'px' }" :src="iframeSrc"
          scrolling="yes" frameborder="0" @load="onLoad"></iframe>
      </div>
    </section>
    <section class="sec3">
      <el-carousel autoplay class="copy-trade-slides" dotsClass="copy-trade-dot" :autoplaySpeed="5000"
        :pauseOnHover="true" arrow="never">
        <el-carousel-item>
          <div class="cont">
            <p class="mobile_title">{{ $t('tradingTool.copytrade.copytrade3') }}</p>
            <img src="@/assets/images-1/tradingTool/copytrade_sec3_1.png" alt="" />
            <div>
              <p class="title">{{ $t('tradingTool.copytrade.copytrade3') }}</p>
              <p>{{ $t('tradingTool.copytrade.copytrade4') }}</p>
              <p>{{ $t('tradingTool.copytrade.copytrade5') }} </p>
              <button @click="goUrl">{{ $t('tradingTool.copytrade.tradeNow') }}</button>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="cont">
            <p class="mobile_title">{{ $t('tradingTool.copytrade.copytrade3') }}</p>
            <img src="@/assets/images-1/tradingTool/copytrade_sec3_2.png" alt="" />
            <div>
              <p class="title">{{ $t('tradingTool.copytrade.copytrade3') }}</p>
              <p>{{ $t('tradingTool.copytrade.copytrade6') }}</p>
              <p>{{ $t('tradingTool.copytrade.copytrade7') }} </p>
              <button @click="goUrl">{{ $t('tradingTool.copytrade.tradeNow') }}</button>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="cont">
            <p class="mobile_title">{{ $t('tradingTool.copytrade.copytrade3') }}</p>
            <img src="@/assets/images-1/tradingTool/copytrade_sec3_3.png" alt="" />
            <div>
              <p class="title">{{ $t('tradingTool.copytrade.copytrade3') }}</p>
              <p>{{ $t('tradingTool.copytrade.copytrade8') }}</p>
              <p>{{ $t('tradingTool.copytrade.copytrade9') }}</p>
              <button @click="goUrl">{{ $t('tradingTool.copytrade.tradeNow') }}</button>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </section>
    <section class="sec4">
      <img class="left_bg" src="@/assets/images-1/tradingTool/copytrade_sec4_1.png" alt="" />
      <img class="right_bg" src="@/assets/images-1/tradingTool/copytrade_sec4_2.png" alt="" />
      <div class="bg">
        <div class="left">
          <p class="title">
            {{ $t('tradingTool.copytrade.copytrade10') }}
          </p>
          <div class="cont">
            <p>{{ $t('tradingTool.copytrade.copytrade11') }}</p>
            <p>{{ $t('tradingTool.copytrade.copytrade12') }}</p>
            <p>{{ $t('tradingTool.copytrade.copytrade13') }}</p>
            <p>{{ $t('tradingTool.copytrade.copytrade14') }}</p>
            <p>{{ $t('tradingTool.copytrade.copytrade15') }}</p>
            <p>{{ $t('tradingTool.copytrade.copytrade16') }}</p>
            <button @click="goUrl">{{ $t('tradingTool.copytrade.copytradeBtn1') }}</button>
          </div>
        </div>
        <div class="right">
          <p class="title">
            {{ $t('tradingTool.copytrade.copytrade17') }}
          </p>
          <div class="cont">
            <p>{{ $t('tradingTool.copytrade.copytrade18') }}</p>
            <p>{{ $t('tradingTool.copytrade.copytrade19') }}</p>
            <p>{{ $t('tradingTool.copytrade.copytrade20') }}</p>
            <p>{{ $t('tradingTool.copytrade.copytrade21') }}</p>
            <button @click="goUrl">{{ $t('tradingTool.copytrade.copytradeBtn2') }}</button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'copytrade',
  data() {
    return {
      copyTradeLangList: [
        { value: 'zh_CN', label: 'zh-cn' },
        { value: 'en_US', label: 'en' },
        { value: 'vi', label: 'vi' },
        { value: 'th', label: 'th' },
        { value: 'ko', label: 'ko' },
        { value: 'ja', label: 'ja' },
        { value: 'es', label: 'es' },
        { value: 'ar', label: 'ar' },
      ],
      iframeHeight: 0,
      loading: true
    };
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    },
    copyTradeLang() {
      const current = this.copyTradeLangList.find((item) => item.value === this.lang)
      return current ? current.label : 'en_US'
    },
    iframeSrc() {
      return `https://rating.startrader.com/widgets/ratings?widgetKey=social_platform_ratings&theme=light&lang=${this.copyTradeLang}`
    }
  },
  methods: {
    onLoad() {
      this.loading = false
      this.iframeHeight = 1000
    },
    goUrl() {
      window.open('https://copytrade.startrader.com/portal/', '_blank')
    }
  }
};
</script>

<style lang="scss">
.trading_tool_copytrade {

  button {
    border: none;
    position: relative;
    display: inline-block;
    white-space: nowrap;
    text-align: center;
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
  }

  .sec1 {
    height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
    padding: 0 25%;
    background: url('~@/assets/images-1/tradingTool/copytrade_sec1_bg.png') no-repeat;
    background-size: cover;
    background-position: center;
    color: $white;
    border-radius: 8px 8px 0 0;

    @include screen-mobile {
      height: auto;
      padding: 24px 40px;
    }

    @media (min-width: 781px) and (max-width: 1200px) {
      padding: 0 12%;
    }

    .title {
      font-size: 32px;
      line-height: 48px;
      font-weight: 700;
      margin-bottom: 16px;

      @include screen-mobile {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .word {
      font-size: 16px;
      line-height: 24px;

      @include screen-mobile {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 16px;
      }
    }

    .line {
      margin: 24px 0 16px 0;
      width: 74px;
      height: 1px;
      background: $white;

      @include screen-mobile {
        display: none;
      }
    }

    button {
      background: $primary;
      color: $secondary;
    }

    button:hover {
      box-shadow: 0px 4px 20px rgba(90, 214, 207, 0.6);
    }
  }

  .sec2 {
    background: $secondary;
    padding: 60px 16px;

    @include screen-mobile {
      padding: 32px 0;
    }

    .title {
      font-size: 32px;
      line-height: 48px;
      color: $white;
      font-weight: 700;
      margin-bottom: 24px;
      text-align: center;

      @include screen-mobile {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 16px;
      }
    }

    .trade-container {
      padding: 16px;
      background: #fafafa;
      box-shadow: 0px 8px 20px rgba(14, 18, 54, 0.1);
      border-radius: 16px;

      @include screen-mobile {
        padding: 0;
      }
    }
  }

  .sec3 {
    background: linear-gradient(107.98deg, #7A3CE3 23.53%, #68E0D5 77.5%);
    position: relative;
    overflow: hidden;

    @include screen-mobile {
      background: linear-gradient(136.66deg, #7A3CE3 9.44%, #68E0D5 84.46%);
      @include rtl-sass-prop(padding-left, padding-right, 24px);
    }

    .copy-trade-slides.el-carousel {

      .el-carousel__container {
        height: 560px;

        @include screen-mobile {
          height: 420px;
        }

        .el-carousel__item {

          .cont {
            width: 100%;
            height: 100%;
            display: flex;
            @include rtl-sass-prop(margin-left, margin-right, 40px);
            z-index: 2;
            overflow: hidden;

            @include screen-mobile {
              display: block;
            }

            .mobile_title {
              font-size: 32px;
              color: $white;
              line-height: 48px;
              font-weight: 600;
              margin-bottom: 16px;
              display: none;

              @include screen-mobile {
                display: block;
                margin: 32px 0 16px 12px;
              }
            }

            img {
              width: 280px;
              height: 280px;
              @include rtl-sass-prop(margin-right, margin-left, 56px);
              margin-top: 160px;

              @include screen-mobile {
                width: 90px;
                height: 90px;
                @include rtl-sass-prop(margin-right, margin-left, 0);
                margin-top: 0;
              }

              @media (min-width: 781px) and (max-width: 992px) {
                width: 160px;
                height: 160px;
                @include rtl-sass-prop(margin-right, margin-left, 18px);
              }

              @media (min-width: 993px) and (max-width: 1440px) {
                width: 200px;
                height: 200px;
                @include rtl-sass-prop(margin-right, margin-left, 24px);
              }
            }

            >div {
              margin-top: 100px;

              @include screen-mobile {
                margin-top: 24px;
              }

              .title {
                font-size: 32px;
                color: $white;
                line-height: 48px;
                font-weight: 600;
                margin-bottom: 40px;

                @include screen-mobile {
                  display: none;
                }
              }

              p:nth-child(2) {
                font-size: 18px;
                color: $primary;
                line-height: 28px;
                font-weight: 600;
                margin-bottom: 16px;

                @include screen-mobile {
                  margin: 0 0 16px 12px;
                }
              }

              p:nth-child(3) {
                max-width: 300px;
                font-size: 14px;
                color: #EDEDF2;
                line-height: 22px;
                margin-bottom: 60px;

                @include screen-mobile {
                  max-width: 70%;
                  height: 66px;
                  margin: 0 0 16px 12px;
                }
              }

              button {
                background: transparent;
                color: $white;
                font-size: 14px;
                line-height: 22px;
                border: 1px solid $white;
                padding: 5px 12px;

                @include screen-mobile {
                  @include rtl-sass-prop(margin-left, margin-right, 12px);
                }
              }

              button:hover {
                border: 1px solid $primary;
                color: $primary;
              }
            }
          }

          &:nth-child(1) {
            .cont {
              background: url('~@/assets/images-1/tradingTool/copytrade_sec3_4.png') no-repeat;
            }
          }

          &:nth-child(2) {
            .cont {
              background: url('~@/assets/images-1/tradingTool/copytrade_sec3_5.png') no-repeat;
            }
          }

          &:nth-child(3) {
            .cont {
              background: url('~@/assets/images-1/tradingTool/copytrade_sec3_6.png') no-repeat;
            }
          }

          &:nth-child(1) .cont,
          &:nth-child(2) .cont,
          &:nth-child(3) .cont {
            @media (max-width: 1920px) {
              background-size: 580px 580px;
              @include rtl-sass-prop(margin-left, margin-right, 20px);
              @include rtl-sass-prop-dual(background-position, right -100px top 120px, background-position, left -100px top 120px);
            }

            @media (min-width: 1920px) {
              background-size: 770px 770px;
              @include rtl-sass-prop(margin-left, margin-right, 120px);
              @include rtl-sass-prop-dual(background-position, right -40px top 50px, background-position, left -40px top 50px);
            }

            @media (max-width: 1440px) {
              background-size: 580px 580px;
              @include rtl-sass-prop(margin-left, margin-right, 20px);
              @include rtl-sass-prop-dual(background-position, right -100px top 120px, background-position, left -100px top 120px);
            }

            @media (max-width: 1200px) {
              background-size: 540px 540px;
              @include rtl-sass-prop(margin-left, margin-right, 20px);
              @include rtl-sass-prop-dual(background-position, right -100px top 180px, background-position, left -100px top 180px);
            }

            @media (max-width: 992px) {
              background-size: 500px 500px;
              @include rtl-sass-prop(margin-left, margin-right, 0px);
              @include rtl-sass-prop-dual(background-position, right -100px top 220px, background-position, left -100px top 220px);
            }

            @media (max-width: 780px) {
              background-size: 270px 270px;
              @include rtl-sass-prop(margin-left, margin-right, 0px);
              @include rtl-sass-prop-dual(background-position, right -60px top 224px, background-position, left -60px top 224px);
            }
          }
        }
      }

      .el-carousel__indicators {
        bottom: 60px;

        @media (max-width: 780px) {
          bottom: 32px;
        }

        .el-carousel__indicator {
          padding: 0;
          margin: 0 4px;

          .el-carousel__button {
            width: 8px;
            height: 8px;
            padding: 0;
            border-radius: 8px;
            background: $white;
            opacity: 1;
          }

          &.is-active {
            .el-carousel__button {
              width: 16px;
              background: $primary;
            }
          }
        }
      }
    }
  }

  .sec4 {
    position: relative;
    background: linear-gradient(-60deg, #7A3CE3 6.75%, #793EE2 7.7%, #7377DD 32.49%, #6EA4DA 54.42%, #6AC5D7 74.45%, #68D8D5 94.56%, #68E0D5 102.1%);
    overflow: hidden;

    img {
      width: 340px;
      height: 460px;
    }

    .left_bg {
      position: absolute;
    }

    .right_bg {
      position: absolute;
    }

    @media (max-width: 1920px) {
      .left_bg {
        bottom: -70px;
        @include rtl-sass-prop(left, right, 20px);
      }

      .right_bg {
        bottom: -60px;
        @include rtl-sass-prop(right, left, 0);
      }

    }

    @media (min-width: 1920px) {
      .left_bg {
        bottom: -70px;
        @include rtl-sass-prop(left, right, 120px);
      }

      .right_bg {
        bottom: -60px;
        @include rtl-sass-prop(right, left, 120px);
      }
    }

    @media (max-width: 1440px) {
      .left_bg {
        bottom: -80px;
        @include rtl-sass-prop(left, right, 0);
      }

      .right_bg {
        bottom: -80px;
        @include rtl-sass-prop(right, left, 0);
      }
    }

    @media (max-width: 1200px) {
      img {
        width: 272px;
        height: 368px;
      }

      .left_bg {
        bottom: -60px;
        @include rtl-sass-prop(left, right, 0);
      }

      .right_bg {
        bottom: -60px;
        @include rtl-sass-prop(right, left, 0);
      }
    }

    @media (max-width: 992px) {
      img {
        width: 238px;
        height: 322px;
      }

      .left_bg {
        bottom: -60px;
        @include rtl-sass-prop(left, right, 0);
      }

      .right_bg {
        bottom: -60px;
        @include rtl-sass-prop(right, left, 0);
      }
    }

    @media (max-width: 780px) {
      img {
        width: 178px;
        height: 240px;
      }

      .left_bg {
        top: 25%;
        @include rtl-sass-prop(left, right, 0);
      }

      .right_bg {
        bottom: 0;
        @include rtl-sass-prop(right, left, 0);
      }
    }

    .bg {
      width: 100%;
      height: 100%;
      display: flex;
      position: relative;
      z-index: 2;

      @include screen-mobile {
        display: block;
      }
    }

    .left,
    .right {
      width: 50%;
      padding: 60px 8%;

      @include screen-mobile {
        width: 100%;
        padding: 32px;
      }

      .title {
        font-size: 32px;
        color: $white;
        line-height: 48px;
        font-weight: 600;
        margin-bottom: 40px;

        @include screen-mobile {
          margin-bottom: 32px;
        }
      }

      .cont {
        display: flex;
        flex-direction: column;

        p {
          font-size: 14px;
          color: $white;
          line-height: 22px;
          margin-bottom: 16px;
          position: relative;
          width: fit-content;
        }

        button {
          background: transparent;
          color: $white;
          font-size: 14px;
          line-height: 22px;
          border: 1px solid $white;
          padding: 5px 12px;
          margin-top: 20px;
        }

        button:hover {
          border: 1px solid $primary;
          color: $primary;
        }
      }

      &.left {
        background: rgba(131, 56, 236, 0.6);

        .title {
          @include rtl-sass-prop-dual(text-align, right, text-align, left);
        }

        .cont {
          align-items: flex-end;

          p {
            @include rtl-sass-prop-dual(text-align, right, text-align, left);
            @include rtl-sass-prop(margin-right, margin-left, 24px);

            &::after {
              content: '';
              position: absolute;
              display: block;
              top: 4px;
              @include rtl-sass-prop(right, left, -24px);
              bottom: 0;
              width: 14px;
              height: 14px;
              background: url('~@/assets/images-1/tradingTool/icon_yellow_check.png') no-repeat;
              background-size: 100% 100%;
              background-position: center center;
            }
          }
        }
      }

      &.right {
        background: rgba(131, 56, 236, 0.65);

        .cont {
          align-items: flex-start;

          p {
            @include rtl-sass-prop(margin-left, margin-right, 24px);

            &::before {
              content: '';
              position: absolute;
              display: block;
              top: 4px;
              @include rtl-sass-prop(left, right, -24px);
              bottom: 0;
              width: 14px;
              height: 14px;
              background: url('~@/assets/images-1/tradingTool/icon_yellow_check.png') no-repeat;
              background-size: 100% 100%;
              background-position: center center;
            }
          }
        }
      }
    }
  }
}
</style>
