<template>
  <div class="trading_tool_pamm">
    <section class="sec1">
      <p class="title">{{ $t('tradingTool.pamm.pammSystem') }}</p>
      <p class="word">{{ $t('tradingTool.pamm.pammSystemPopular') }}</p>
      <p class="word">{{ $t('tradingTool.pamm.mostAdvantage') }}</p>
      <div class="line"></div>
    </section>
    <section class="sec2">
      <p class="title1">PAMM</p>
      <p class="title2">{{ $t('tradingTool.pamm.pammTitle') }}</p>
      <div class="cont">
        <div class="word word1">
          <p>{{ $t('tradingTool.pamm.pammText1') }}</p>
          <p>{{ $t('tradingTool.pamm.pammText2') }}</p>
          <p>{{ $t('tradingTool.pamm.pammText3') }}</p>
        </div>
        <img src="@/assets/images-1/tradingTool/pamm_sec2_1.png" alt="" />
        <div class="word word2">
          <p>{{ $t('tradingTool.pamm.pammText4') }}</p>
          <p>{{ $t('tradingTool.pamm.pammText5') }}</p>
          <p>{{ $t('tradingTool.pamm.checkPammMange') }}</p>
        </div>
      </div>
    </section>
    <section class="sec3">
      <div class="bind">
        <p class="title">{{ $t('tradingTool.pamm.pammAccount') }}</p>
        <div class="cont">
          <div class="account" @click="openPamm(1)">
            <img src="@/assets/images-1/tradingTool/pamm_sec3_1.png" alt="" />
            <p>{{ $t('tradingTool.pamm.pammMangeAccount') }}</p>
          </div>
          <div class="account" @click="openPamm(2)">
            <img src="@/assets/images-1/tradingTool/pamm_sec3_2.png" alt="" />
            <p>{{ $t('tradingTool.pamm.pammInvestAccount') }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'pamm',
  computed: {
    getPammLinks() {
      return this.$config.getPammLinks(this.regulator)
    }
  },
  methods: {
    openPamm(type) {
      if (type === 1) {
        window.open(this.getPammLinks.manager, '_blank')
      } else {
        window.open(this.getPammLinks.investor, '_blank')
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.trading_tool_pamm {

  .sec1 {
    height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding: 0 25%;
    background: url('~@/assets/images-1/tradingTool/pamm_sec1_bg.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    color: $white;
    border-radius: 8px 8px 0 0;

    @include screen-mobile {
      height: auto;
      padding: 32px 40px;
    }

    @media (min-width: 781px) and (max-width: 1200px) {
      padding: 0 12%;
    }

    .title {
      font-size: 32px;
      line-height: 48px;
      font-weight: 700;
      margin-bottom: 16px;

      @include screen-mobile {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .word {
      font-size: 16px;
      line-height: 24px;

      @include screen-mobile {
        font-size: 12px;
        line-height: 18px;
      }
    }

    .line {
      width: 74px;
      height: 1px;
      background: $white;
      margin-top: 24px;

      @include screen-mobile {
        width: 24px;
        margin-top: 16px;
      }
    }
  }

  .sec2 {
    background: $white;
    padding: 60px 48px;

    @include screen-mobile {
      padding: 32px 24px;
    }

    @media (min-width: 781px) and (max-width: 1200px) {
      padding: 60px 24px;
    }

    .title1 {
      font-size: 32px;
      line-height: 48px;
      color: $text-title;
      font-weight: 700;
      text-align: center;

      @include screen-mobile {
        font-size: 18px;
        line-height: 28px;
        @include rtl-sass-prop-dual(text-align, left, text-align, right);
      }
    }

    .title2 {
      font-size: 18px;
      line-height: 28px;
      color: #333840;
      font-weight: 600;
      margin: 16px 0;
      text-align: center;

      @include screen-mobile {
        font-size: 16px;
        line-height: 24px;
        @include rtl-sass-prop-dual(text-align, left, text-align, right);
      }
    }

    .cont {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      @include screen-mobile {
        flex-direction: column;
      }

      img {
        margin: 0 74px;
        width: 253px;
        height: 213px;

        @include screen-mobile {
          margin: 16px 0;
          width: 243px;
          height: 205px;
        }

        @media (min-width: 781px) and (max-width: 1200px) {
          margin: 0 32px;
        }
      }

      .word {
        width: 366px;

        @include screen-mobile {
          width: 100%;
        }

        p {
          font-size: 14px;
          color: #333840;
          line-height: 22px;
          margin-bottom: 16px;
          display: flex;
          align-items: center;
          position: relative;
          padding-left: 22px;
          @include rtl-sass-prop(padding-left, padding-right, 22px);

          &::before {
            content: '';
            position: absolute;
            display: block;
            top: 3px;
            @include rtl-sass-prop(left, right, 0);
            bottom: 0;
            width: 14px;
            height: 14px;
            background: url('~@/assets/images-1/tradingTool/icon_blue_check.png') no-repeat;
            background-size: 100% 100%;
            background-position: center center;
          }
        }

        p:nth-last-child(1) {
          margin-bottom: 0px;
        }
      }
    }
  }

  .sec3 {
    background: $secondary;
    text-align: center;
    padding: 60px 24px 108px;
    border-radius: 0 0 8px 8px;

    @include screen-mobile {
      padding: 32px 24px;
    }

    .title {
      font-size: 32px;
      color: $white;
      line-height: 48px;
      font-weight: 700;
      margin-bottom: 24px;

      @include screen-mobile {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 16px;
      }
    }

    .cont {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      @include screen-mobile {
        flex-direction: column;
      }

      .account {
        width: 440px;
        text-align: center;
        padding: 24px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid transparent;
        border-radius: 16px;
        cursor: pointer;

        @include screen-mobile {
          width: 100%;
        }

        img {
          width: 40px;
          height: 40px;
        }

        p {
          font-size: 14px;
          color: $white;
          line-height: 22px;
          margin-top: 16px;
        }

        &:hover {
          border: 1px solid $primary;
          box-shadow: 0px 8px 20px rgba(90, 214, 207, 0.3);
          transform: translateY(-4px)
        }
      }

      .account+.account {
        @include rtl-sass-prop(margin-left, margin-right, 72px);

        @include screen-mobile {
          @include rtl-sass-prop(margin-left, margin-right, 0);
          margin-top: 16px;
        }
      }

    }
  }
}
</style>
