<template>
  <div class="trading_tool_webtrade">
    <p class="title">Web Trader</p>
    <div class="container" v-loading="loading">
      <div id="webterminal" style="width: 100%; height: 600px"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'webtrader',
  data() {
    return {
      loading: true,
      webtradeCode: this.$config.getWebtradeCode(this.$store.state.common.regulator),
    };
  },
  created() {
    const script1 = document.createElement('script')
    script1.src = 'https://trade.mql5.com/trade/widget.js'
    script1.onload = () => {
      const script2 = document.createElement('script')
      script2.innerHTML = this.webtradeCode
      this.loading = false
      document.body.append(script2)
    }
    document.body.append(script1)
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
.trading_tool_webtrade {
  background: $secondary;
  padding: 60px 16px 16px 16px;
  border-radius: 8px;

  @include screen-mobile {
    padding: 32px 0;
  }

  .title {
    font-size: 32px;
    color: $white;
    line-height: 48px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 24px;

    @include screen-mobile {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 16px;
    }
  }

  .container {
    padding: 24px;
    background: #fafafa;
    box-shadow: 0px 8px 20px rgba(14, 18, 54, 0.1);
    border-radius: 16px;

    @include screen-mobile {
      padding: 0;
    }
  }
}
</style>
 