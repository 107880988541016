<template>
  <PageWrapper :tabs="getTabs" :tabActive.sync="tabActive" class="trading_tool">
    <component :is="currentComponent" @goWebTrader="goWebTrader" />
  </PageWrapper>
</template> 

<script>
import Mt4 from './components/Mt4.vue'
import Pamm from './components/Pamm.vue'
import Copytrade from './components/Copytrade.vue'
import Webtrader from './components/Webtrader.vue'

export default {
  name: 'tradingTool',
  components: {
    Mt4,
    Pamm,
    Copytrade,
    Webtrader
  },
  data() {
    return {
      tabActive: 'tab1',
    };
  },
  watch: {
    getTabs: {
      handler(val) {
        if (val && val.length > 0) {
          this.tabActive = val[0].name
        }
      },
      immediate: true,
    },
  },
  computed: {
    getTabs() {
      return this.$config.getTradingToolTabs(this.regulator)
    },
    currentComponent() {
      if (this.tabActive === 'tab1') {
        return Mt4
      } else if (this.tabActive === 'tab2') {
        return Pamm
      } else if (this.tabActive === 'tab3') {
        return Copytrade
      } else {
        return Webtrader
      }
    }
  },
  methods: {
    goWebTrader() {
      this.tabActive = 'tab4'
    },
  }
};
</script>

<style lang="scss" scoped>
</style>
